import store from '@/store'
import router from '@/router'
import { Notification } from 'element-ui'

class Base {
  /**
   * 初始化
   */
  constructor() {
    this.$notify = Notification
  }

  getStoreInstance() {
    return store
  }

  /**
   * 获取当前登录用户的ID
   */
  getAccountId() {
    return store.state.user.uid
  }

  getTalkParams() {
    let { talkType, receiverId, indexName } = store.state.dialogue

    return { talkType, receiverId, indexName }
  }

  /**
   * 判断消息是否来自当前对话
   *
   * @param {Number} talkType 聊天消息类型[1:私信;2:群聊;]
   * @param {Number} senderId 发送者ID
   * @param {Number} receiverId 接收者ID
   */
  isTalk(talkType, senderId, receiverId) {
    let params = this.getTalkParams()

    if (talkType != params.talkType) {
      return false
    } else if (
      params.receiverId == receiverId ||
      params.receiverId == senderId
    ) {
      return true
    }

    return false
  }

  /**
   * 判断用户是否打开对话页
   */
  isTalkPage() {
    let path = router.currentRoute.path
    return !(path != '/message' && path != '/')
  }
}

export default Base
