import store from '@/store'
import Base from './base'

/**
 * 好友状态事件
 */
class Talk extends Base {
  /**
   * @var resource 资源
   */
  resource

  /**
   * 发送者ID
   */
  senderId = 0

  /**
   * 接收者ID
   */
  receiverId = 0

  /**
   * 聊天类型[1:私聊;2:群聊;]
   */
  talkType = 0

  /**
   * 初始化构造方法
   *
   * @param {Object} resource Socket消息
   */
  constructor(resource) {
    super()
    this.senderId = resource.senderId
    this.receiverId = resource.receiverId
    this.talkType = resource.talkType
    this.resource = resource
  }

  /**
   * 处理 event
   * @returns
   */
  handle() {
    console.log(this.resource)
    var eventMessage = JSON.parse(this.resource)
    var event = eventMessage.event
    var payload = eventMessage.payload

    const receiverId = payload.receiverId
    var justify = 'start'
    if (receiverId === this.userId) {
      justify = 'end'
    }

    var msg = {}
    msg.senderId = payload.senderId
    msg.content = payload.content
    var item = {
      content: msg,
      justify: justify
    }
    console.log(item)
    store.commit('PUSH_TALK_ITEM', item)
  }
}

export default Talk
