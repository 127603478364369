<template>
  <div id="dplayer" ref="dplayer" style="height: 480px;" />
</template>

<script>
import { videoUrl } from '@/api/video'
import SocketInstance from '@/utils/ws/socket-instance'

import flvjs from 'flv.js'
import DPlayer from 'dplayer'
import { getAccessToken } from '@/utils/auth'

export default {
  name: 'VideoPlayer',
  props: {
    videoProp: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      wsClient: null,
      intervalEvent: null,
      flvjs,
      DPlayer,
      danmaku: {
        api: process.env.VUE_APP_SERVER_URL + '/api/comment/danmaku/',
      },
      getUrl: true,
      userToken: null
    }
  },
  created() {
    this.userToken = getAccessToken()
    this.danmaku.token = this.userToken
  },
  mounted() {
    const videoId = this.videoProp.videoId
    if (this.getUrl) {
      this.getVideoUrl(videoId)
    }
  },
  methods: {
    getVideoUrl(videoId) {
      videoUrl(videoId).then(res => {
        if (res.code === 0) {
          var sendEvent = false
          if (this.userToken != null) {
            const wsUrl = 'ws:' + process.env.VUE_APP_SERVER_URL + '/ws/progress?token=' + this.userToken
            this.wsClient = new SocketInstance(wsUrl)
            this.wsClient.connect()
            sendEvent = true
          }

          const urlType = res.data.type
          if (urlType === 'mp4') {
            const urls = res.data.urls
            for (const url of urls) {
              url.type = 'normal'
            }
            this.initMp4Player(this.videoProp.userId, videoId, this.videoProp.coverUrl, urls, res.data.currentTime, sendEvent)
          } else if (urlType === 'flv') {
            const urls = res.data.urls
            this.initFlvPlayer(this.videoProp.userId, videoId, this.videoProp.coverUrl, urls, res.data.currentTime, sendEvent)
          } else {
            this.$notify.error({
              message: '视频 url 类型不合法',
              type: 'warning',
              duration: 3000
            })
          }
        } else {
          this.$notify.error({
            message: '视频 url 获取失败',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify.error({
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    danmakuConfig() {
      // TODO 获取弹幕配置，将 videoUrl 作为本函数的回调
    },
    initMp4Player(userId, videoId, coverUrl, urls, pos, sendEvent) {
      const player = new DPlayer({
        container: document.querySelector('#dplayer'),
        lang: 'zh-cn',
        screenshot: true,
        autoplay: false,
        volume: 0.1,
        mutex: true,
        video: {
          pic: coverUrl,
          defaultQuality: 0,
          quality: urls,
          hotkey: true
        },
        danmaku: {
          id: videoId,
          maximum: 10000,
          api: this.danmaku.api,
          token: this.userToken,
          bottom: '15%',
          unlimited: true
        }
      })

      // 设置音量
      // player.volume(0.1, true, false)
      // 跳转到上次看到的位置
      player.seek(pos)

      var ended = false
      /* 事件绑定 */
      const that = this
      player.on('play', function() {
        if (sendEvent) {
          clearInterval(that.intervalEvent)
          that.intervalEvent = setInterval(() => {
            if (!ended) {
              const payload = {}
              payload.mediaId = videoId
              payload.mediaType = 1
              payload.currentTime = player.video.currentTime
              payload.ended = ended

              const jsonData = {}
              jsonData.event = 'media_progress'
              jsonData.data = JSON.stringify(payload)

              that.wsClient.send(jsonData)
            }
          }, 5000)
        }
      })
      player.on('ended', () => {
        clearInterval(that.intervalEvent)
        ended = true
        if (sendEvent) {
          const payload = {}
          payload.mediaId = videoId
          payload.mediaType = 1
          payload.currentTime = player.video.currentTime
          payload.ended = ended

          const jsonData = {}
          jsonData.event = 'media_progress'
          jsonData.data = JSON.stringify(payload)

          that.wsClient.send(jsonData)
        }
      })

      player.on('volumechange', () => {
        console.log('声音改变')
      })
    },
    initFlvPlayer(userId, videoId, coverUrl, urls, pos, sendEvent) {
      const player = new DPlayer({
        container: document.getElementById('dplayer'),
        lang: 'zh-cn',
        screenshot: true,
        autoplay: false,
        volume: 0.1,
        mutex: true,
        video: {
          pic: coverUrl,
          defaultQuality: 0,
          quality: urls,
          hotkey: true,
          type: 'customFlv',
          customType: {
            customFlv: function(video, player) {
              const flvPlayer = flvjs.createPlayer({
                type: 'flv',
                url: video.src
              })
              flvPlayer.attachMediaElement(video)
              flvPlayer.load()
            }
          }
        }
      })

      // 跳转到上次看到的位置
      player.seek(pos)
      /* 事件绑定 */
      player.on('ended', () => {
      })
      player.on('volumechange', () => {
        console.log('声音改变')
      })
    }
  }
}
</script>

<style>
</style>
