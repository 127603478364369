<template>
  <el-col style="padding-right: 7px; padding-left: 7px">
    <div style="cursor: pointer" :title="video.title">
      <el-card :body-style="{ padding: '0px' }" class="card">
        <el-col :md="8">
          <router-link target="_blank" :to="`/video/${video.videoId}`">
            <div class="imgs">
              <el-image
                lazy
                fit="cover"
                :src="video.coverUrl"
                class="coverImg"
              />
              <span style="position: absolute; bottom: 0; left: 0; color:white">
                <i v-if="video.horizontal" class="el-icon-monitor" />
                <i v-else class="el-icon-mobile-phone" />
              </span>
              <span style="position: absolute; bottom: 0; right: 0; color:white"> {{ video.duration }} </span>
            </div>
          </router-link>
        </el-col>
        <el-col :md="16">
          <div style="padding: 14px">
            <router-link style="text-decoration-line: none" target="_blank" :to="`/video/${video.videoId}`">
              <span style="left: 0;margin-bottom: 0px;color: black;">{{ video.title | ellipsis }}</span>
            </router-link>
          </div>
          <div style="padding: 14px">
            <span style="left: 0;margin-bottom: 0px;color: black;">
              <router-link style="text-decoration-line: none" target="_blank" :to="`/user/${video.user.userId}`">
                <i class="el-icon-user"> {{ video.user.screenName | ellipsisUsername }} </i></router-link>
            </span>
          </div>
          <div style="padding: 14px">
            <el-col :md="6">
              <span class="el-icon-video-play" style="left: 0;margin-bottom: 0px;color: black;">
                {{ video.view }}
              </span>
            </el-col>
            <el-col :md="6">
              <span class="el-icon-s-comment" style="left: 0;margin-bottom: 0px;color: black;">
                {{ video.comment }}
              </span>
            </el-col>
          </div>
        </el-col>
      </el-card>
    </div>
  </el-col>
</template>

<script>
export default {
  name: 'SideVideoCard',
  filters: {
    ellipsis(value) {
      if (!value) return ''
      const max = 20
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    },
    ellipsisUsername(value) {
      if (!value) return ''
      const max = 10
      if (value.length > max) {
        return value.slice(0, max) + '...'
      }
      return value
    }
  },
  props: {
    video: {
      type: Object,
      default: null
    },
    // 时间前的描述
    dateTit: {
      type: String,
      default: ''
    }
  },
  methods: {
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .coverImg {
    height: 80px !important;
  }
}

.coverImg {
  width: 100%;
  height: 120px;
  display: block;
}

.card {
  margin-bottom: 20px;
  transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
}

.imgs {
  position: relative;
}
</style>
