import store from '@/store'
import WsSocket from '@/utils/ws/ws-socket'
import TalkEvent from '@/utils/ws/event/talk'

/**
 * SocketInstance 连接实例
 *
 * 注释: 所有 WebSocket 消息接收处理在此实例中处理
 */
class SocketInstance {
  /**
   * WsSocket 实例
   */
  socket

  /**
   * SocketInstance 初始化实例
   */
  constructor(wsUrl) {
    this.socket = new WsSocket(
      () => { return wsUrl },
      {
        onError: evt => {
          console.log('Websocket 连接失败回调方法')
          store.commit('UPDATE_SOCKET_STATUS', 'Offline')
        },
        // Websocket 连接成功回调方法
        onOpen: evt => {
          // 更新 WebSocket 连接状态
          store.commit('UPDATE_SOCKET_STATUS', 'Online')
          console.log('ws 连接成功')
        },
        // Websocket 断开连接回调方法
        onClose: evt => {
          // 更新 WebSocket 连接状态
          store.commit('UPDATE_SOCKET_STATUS', 'Offline')
          console.log('ws 连接断开')
        }
      }
    )

    this.registerEvents()
  }

  // 连接 WebSocket 服务
  connect() {
    this.socket.connection()
  }

  /**
   * 注册回调消息处理事件
   */
  registerEvents() {
    this.socket.on('heartbeat', (payload, data) => {
      console.log(data)
    })
    this.socket.on('event_error', (payload, data) => {})
    this.socket.on('event_talk', (payload, data) => {
      new TalkEvent(data).handle()
      /* const title = payload.title
      const content = payload.content
      this.sendNotification(title, content)
      Notification({
        title: payload.title,
        message: payload.content.substring(0, 30),
        type: 'warning',
        duration: 5000
      })*/
    })
  }
  /* sendNotification(title, content) {
    new Notification(title, {
      body: content,
      icon: 'https://pic1.zhuanstatic.com/zhuanzh/50b6ffe4-c7e3-4317-bc59-b2ec4931f325.png'
    })
  }*/

  /**
   * 聊天发送数据
   *
   * @param {Object} mesage
   */
  send(mesage) {
    this.socket.send(mesage)
  }

  /**
   * 推送消息
   *
   * @param {String} event 事件名
   * @param {Object} data 数据
   */
  emit(event, data) {
    this.socket.emit(event, data)
  }
}

export default SocketInstance
